const trans = {
  fr: {
    'open_note': 'Heures d\'ouverture du bureau des ventes',
    'open_hours': 'Lundi au vendredi : 9h à 17h',
    'cta': 'Demandez une soumission',
    'menu_1': 'Expérience',
    'menu_2': 'Espaces de<br>rencontres',
    'menu_3': 'Activités<br>d\'équipe',
    'menu_4': 'Services<br>de transport',

    'og_title' : "L'expérience Groupes Tremblant | Affaires, loisir et consolidation d'équipe",
    'og_title_soumission' : "Demandez une soumission | L'expérience Groupes Tremblant",
    'og_desc' : "Séjourner en groupe à Tremblant, c’est vivre une expérience où s'allient confort et modernité, grands espaces et commodités. Offrez-vous un événement plus grand que nature, dans une atmosphère invitante.",
    'og_desc_soumission' : "Vous voulez organiser un événement corporatif, un colloque ou même un séminaire? Tremblant apportera votre compagnie au sommet!",

    'hero_title': 'L\'expérience<br>Groupes Tremblant',

    'exp_title': 'Découvrez l\'expérience<br>groupes Tremblant',
    'exp_desc': 'Séjourner en groupe à Tremblant, c’est vivre une expérience où s\'allient confort et modernité, grands espaces et commodités. Offrez-vous un événement plus grand que nature, dans une atmosphère invitante. Plus encore, Tremblant, c’est :',
    'exp_text_1': 'Accessibilité et proximité : les avantages d\'un village piétonnier, facilement accessible à partir de Montréal, d\'Ottawa ou de Toronto.',
    'exp_text_2': 'Environnement grandeur nature : plus de 60 000 pieds carrés d\'espaces de réunion dans un décor naturel, hors des sentiers battus.',
    'exp_text_3': 'Une panoplie de restaurants, pour tous les goûts et pour les groupes de toutes envergures.',
    'exp_text_4': 'Une grande variété d\'hébergement confortables et accueillants pour vos invités.',
    'exp_text_5': 'Une offre stimulante d\'activités et d\'excursions pour vos groupes.',
    'exp_text_6': 'Une équipe dévouée pour vous guider et satisfaire tous vos besoins.',

    'heb_title': 'L\'hébergement',
    'heb_desc': 'À flanc de montagne, au cœur du village piétonnier ou aux abords de nos golfs, choisissez le pied-à-terre idéal pour votre groupe.',
    'heb_argument_title_1': 'Variété d\'hôtels',
    'heb_argument_desc_1': '13 hôtels dont 5 de chaînes de renom, 1900 unités d\'hébergements de tout types : chambres d\'hôtel, studio, condos et résidences en montagne, trouvez l\'environnement qui vous convient.',
    'heb_argument_title_2': 'Toutes les commodités',
    'heb_argument_desc_2': 'Piscine privée, spas, saunas, services de massages ou salles de sports, nous offrons une variété de commodités pour vous ressourcer.',
    'heb_argument_title_3': 'Espaces propices aux rencontres',
    'heb_argument_desc_3': 'Des endroits stimulants et chaleureux, pour que vos événements d\'affaires et vos activités de groupe se déroulent dans une atmosphère rassembleuse.',

    'espace_title': 'Espaces de rencontre',
    'espace_desc': 'Repensez votre environnement de travail. Découvrez les avantages de nos espaces de rencontre, aussi reposants qu\'énergisants. L\'essentiel pour atteindre vos objectifs.',
    'espace_argument_title_1': 'Variété de salles',
    'espace_argument_desc_1': 'Organisez un événement réussi dans l\'une de nos 50 salles de réunions, de conférences ou d\'expositions intérieures.',
    'espace_argument_title_2': 'Internet haute vitesse',
    'espace_argument_desc_2': 'Demeurez connectés grâce à notre connexion wifi rapide, pour des réunions facilitées. Bénéficiez d\'une créativité sans limite.',
    'espace_argument_title_3': 'Service de traiteur',
    'espace_argument_desc_3': 'Profitez de nos menus diversifiés. Nous offrons des options à la carte et des buffets, pour que votre équipe refasse le plein d\'énergie.',

    'cdb_title': 'Camp de Base Tremblant, c\'est un espace où les rêves prennent vie et où les ambitions s\'élèvent.',
    'cdb_sub_title': 'Camp de Base Tremblant s’est refait une beauté. Pour des rencontres plus grandes que nature, rendez-vous à Tremblant dès maintenant.',
    'cdb_desc': ``,

    'groups_title': 'Rassemblements<br>groupes et affaires',
    'groups_desc': 'Tremblant accompagne votre groupe dans son ascension vers le succès. Notre formule : un environnement stimulant, des expéditions ressourçantes et une équipe d\'experts qui sait vous orienter. Tout pour un séjour en nature mémorable.',
    'groups_1': 'Affaires',
    'groups_2': 'Loisirs',
    'groups_3': 'Consolidation<br>d\'équipe',

    'activite_title': 'Activités d\'équipe<br>et gastronomie',
    'activite_desc': 'Enrichissez vos rencontres grâce à notre panoplie d\'activités et de découvertes gastronomiques, offertes quatre saisons. Explorez en groupe notre réel terrain de jeu à grande échelle.',
    'activite_argument_title_1': 'Des activités pour tous les goûts',
    'activite_argument_desc_1': 'Bougez et ressourcez-vous en nature grâce à <a href="https://groups.tremblant.ca/outils-de-planification/page/liste-complete-des-activites-selon-la-saison" target="_blank">plus de 40 activités</a>. Que vous soyez en quête d\'amusement, de calme ou de découverte, nous offrons l\'aventure qui vous inspirera.',
    'activite_argument_title_2': 'Restaurants et bars à découvrir',
    'activite_argument_desc_2': 'Explorez Tremblant en tant que destination gourmande, idéale pour partager un délicieux repas. Partez à la découverte de <a href="https://groups.tremblant.ca/outils-de-planification/page/restaurants-bars-et-cafes" target="_blank">plus de 40 restaurants et bars</a> qui sauront satisfaire vos envies.',
    'activite_argument_title_3': 'Expériences de consolidation d\'équipe',
    'activite_argument_desc_3': 'Aventurez-vous dans nos diverses activités de consolidation d\'équipe. Dépassez-vous en groupe et tissez des liens à travers nos nombreuses immersions au coeur de la nature.',

    'transport': 'Transport',
    'vols_title': 'Accessibilité aérienne',
    'vols_sub_title': 'Transportez votre groupe par les airs, directement vers Tremblant',
    'vols_desc': 'Accédez au site de Tremblant par voie aérienne. Le service Porter offre plusieurs vols directs en partance de l’aéroport Billy Bishop de Toronto, en plus de plusieurs correspondances vers Ottawa, Sudbury, Windsor, Thunder Bay, Sault-Sainte-Marie, ainsi que New York, Boston et Chicago.',
    'vols_cta': 'Consultez l\'horaire des vols',
    'vols_cta_link': '/pdf/horaire-hiver-2022-2023-porter-fr.pdf',
    'vols_promo': 'Recevez 2 billets gratuits de ski de semaine lorsque vous atterrissez à l\'Aéroport de Mont-Tremblant (YTM) un dimanche ou lundi et quittez un jeudi ou vendredi. De plus, obtenez 25$ en crédits-jeu promotionnels au Casino de Mont-Tremblant lorsque vous réservez un vol vers l’aéroport international de Mont-Tremblant en provenance de l’aéroport Billy Bishop de Toronto.',
    'vols_promo_video_link': 'https://www.youtube.com/watch?v=Y4Ni0ENC1q0&feature=youtu.be',

    'bus_title': 'Accessibilité Terrestre',
    'bus_sub_title': 'Autobus Galland',
    'bus_desc': 'Service nolisé d\'autocars de luxe de Montréal vers Mont&nbsp;-&nbsp;Tremblant. Faites de vos déplacements une expérience en soi.',
    'bus_cta': 'Planifiez votre itinéraire',
    'bus_cta_link': 'https://groups.tremblant.ca/outils-de-planification/page/accessibilite-terrestre',

    'eco_title': 'Notre engagement écoresponsable',
    'eco_sub_title': 'Tremblant, chef de file en écoresponsabilité',
    'eco_desc': 'Station Mont-Tremblant devient en 2021 la toute première entreprise québécoise à obtenir la certification de niveau 3 du programme Écoresponsable, par Ecocert Canada. Séjourner à Tremblant tout en compensant votre impact environnemental, c\'est possible. Prenez part au développement durable au coeur de Tremblant, pionnière dans son domaine.',
    'eco_cta': 'Contribuez dès aujourd\'hui',
    'eco_cta_link': 'https://www.tremblant.ca/a-propos/ecoresponsabilite',

    'contact_title': 'Coordonnées',
    'copy': '© Tremblant - Tous droits réservés',
    'politique': 'Politique de vie privée',
    'politique_link': 'https://www.alterramtnco.com/politique-de-vie-privee/',
    'condition': 'Conditions d\'utilisation',
    'condition_link': 'https://www.alterramtnco.com/terms-of-use',

    'testimonial_1': `
                Au début, quand je planifiais cet événement, j’appelais chaque hôtel pour demander leurs tarifs et leurs détails. J'ai été ravie de recevoir un appel de Marie-Chantal Thibault, responsable du développement des affaires au Centre de villégiature Tremblant, m’offrant l’option d’obtenir toutes les offres en un seul document. Ça a tellement simplifié ma vie !
              <strong></strong>
              <span>Ferrari Club of America</span>
    `,
    'testimonial_2': `
                Tremblant offre un dépaysement complet et un environnement stimulant qui a permis à toute l'équipe de tisser des liens. Nous avons profité des multiples activités en plein air et des bains scandinaves. Notre voyage de motivation fut un franc succès. Nous reviendrons assurément ! <br />
              <strong>Guillaume Brunet</strong>
              <span>Président chez Substance Radiance</span>
    `,
    'testimonial_3': `
                La soirée de gala au Fairmont Tremblant a été parfaite! Le chef a livré un quatre services à la hauteur ayant plus de 20 allergies alimentaires, un service parfait, des employés heureux de travailler. Bref, une semaine chez vous avec un service platinum de l'arrivée au départ. Bravo à vous tous et remerciement à Michelle Hudon pour son souci de détail et son grand professionnalisme. Une cliente heureuse!
              <strong>Lucie Champagne</strong>
              <span>President of Champagne Événements</span>
    `,
    'testimonial_4': `
                Un GRAND merci à toutes les personnes impliquées dans notre événement corporatif du samedi 12 décembre 2015 au Centre des Congrès Tremblant. Notre expérience clés en main et à notre image fut un succès sur toute la ligne, du jour 1 au jour J! Service personnalisé et à l'écoute de nos besoins, le tout dans une ambiance à la fois décontractée et très professionnelle! Expérience 5*!
              <strong>Stéfanie Bouchard</strong>
              <span>Brioconcept</span>
    `,
    'testimonial_5': `
                Tremblant est l’une des meilleures destinations de voyage de motivation au Canada. Un véritable bijou! Elle devrait être l’une des destinations de choix de tous les organisateurs pour leurs événements et rencontres à venir.
              <strong>Thriven Financial</strong>
    `,
    'testimonial_6': `
                Ce fut un plaisir de travailler avec vous et l’équipe de Tremblant sur la planification et la mise en œuvre de la conférence été 2016 des Producteurs d’œufs du Canada. Votre équipe, sympathique et compétente, nous a assistés avec notre plan d’organisation afin de mettre en place une conférence annuelle très réussie pour environ 300 délégués. Nous avons apprécié votre contribution, votre enthousiasme et votre souci du détail ; mais surtout, nous avons apprécié votre travail acharné et votre capacité d’adaptation. Merci encore de nous avoir aidés à faire de notre conférence été 2016 un événement mémorable!
              <strong>Paula Panetta</strong>
              <span>Les Producteurs d’œufs du Canada</span>
    `,
    'testimonial_7': `
                Entre l'accueil chaleureux auquel nous avons eu droit, l'efficacité des installations et la qualité des services de restauration, tous les éléments étaient réunis pour faire de cette conférence un succès retentissant.
              <strong>Neil Levecque</strong>
              <span>SCHL</span>
    `,
    'testimonial_8': `
                Grâce à son travail assidu et à son expérience, [Marie-Chantal] du développement des affaires de l’Association de villégiature Tremblant a contribué à faire de notre événement un succès. Ce fut un véritable plaisir d’être accueillis par l’hôtel Fairmont. Le personnel de l’hôtel était professionnel et très aimable. Je félicite tout le monde pour la qualité exceptionnelle du séjour, de l’expérience culinaire et du service. 
              <strong>Jackie Brown</strong>
              <span>Cheffe de bureau et administratrice.<br>Teamsters Canada – Bureau de la région de l’Ouest</span>
    `,


    'form_name': 'Prénom',
    'form_last_name': 'Nom',
    'form_mail': 'Courriel',
    'form_phone': 'Téléphone',
    'form_city': 'Ville',
    'form_pays': 'Pays',
    'form_type': 'Type de groupe',
    'form_type_0': 'Choisir',
    'form_type_1': 'Club social',
    'form_type_2': 'Corporatif',
    'form_type_3': 'Association',
    'form_type_4': 'Gouvernement',
    'form_type_5': 'Groupe scolaire',
    'form_type_6': 'Famille / Amis',
    'form_type_7': 'Club sportif / Camp d\'entraînement',
    'form_type_8': 'Mariage',
    'form_type_9': 'Bal de finissants',
    'form_type_10': 'Grossistes',
    'form_type_11': 'Autre',
    'form_business': 'Nom de l\'entreprise (s\'il y a lieu)',
    'form_participants': 'Nombre de participants (18 ans et +)',
    'form_participants_under_18': 'Nombre de participants (moins de 18 ans)',
    'form_arrival': 'Arrivée',
    'form_departure': 'Départ',
    'form_chambre': 'Nombre d\'unités par nuits',
    'form_lodging': 'Type d\'hébergement',
    'form_lodging_0': 'Choisir',
    'form_lodging_1': 'Chambres traditionnelles',
    'form_lodging_2': 'Studios ou condos 1 chambre',
    'form_lodging_3': 'Condos 2 et 3 chambres',
    'form_lodging_4': 'Flexible',
    'form_salle': 'Avez-vous besoin d\'une salle de réunion ?',
    'form_oui': 'oui',
    'form_non': 'non',
    'form_activites': 'Quel(s) type(s) d\'activité(s) vous intéressent ?',
    'form_activites_0': 'Choisir',
    'form_activites_1': 'Ski / Golf',
    'form_activites_2': 'Consolidation d\'équipe / team building',
    'form_activites_3': 'Détente / Spa',
    'form_activites_4': 'Festival / événements',
    'form_activites_5': 'Activités sportives (vélo, raquette, ski de fond, etc.)',
    'form_activites_6': 'Sensations fortes (Arbre en arbre, Ziptrek, karting, etc.)',
    'form_activites_7': 'Forfait party de bureau',
    'form_details': 'Détail des besoins (nourriture, montage, horaire)',
    'form_upload': 'Téléchargez votre demande de soumission détaillée ici',
    'form_upload_file': '(extensions permises: jpeg/jpg/doc/docx/pdf/xls/xlsx/csv)',
    'form_more_details': 'Détails additionnels',
    'form_send': 'Envoyez',
    'ou': 'Ou',

    'promo_hero_title': 'Forfait Party<br>de bureau',
    'promo_hero_deal': 'À partir de <br />215$ par personne',

  },
  en: {
    'open_note': 'Sales office business hours',
    'open_hours': 'Monday to Friday: 9 a.m. to 5 p.m.',
    'cta': 'Request for proposal',
    'menu_1': 'Experience',
    'menu_2': 'Meeting<br>Facilities',
    'menu_3': 'Group<br>Activities',
    'menu_4': 'Transportation',
    
    'og_title' : "The Tremblant Group experience |  Business, leisure and team building",
    'og_title_soumission' : "Request for proposal | The Tremblant Group experience",
    'og_desc' : "Booking group accommodations at Tremblant means experiencing a world of comfort and luxury, of natural settings and amenities. It's time to treat yourself to a larger-than-life experience in a breathtaking atmosphere. Tremblant is more than a resort, it's:",
    'og_desc_soumission' : "Tremblant has everything you need to host a successful gathering. We offer guests the opportunity to work in a stimulating environment and participate in invigorating expeditions and activities.",

    'hero_title': 'TAKING CARE OF BUSINESS<br>BY TREMBLANT',

    'exp_title': 'Discover the Tremblant<br>Group Experience',
    'exp_desc': 'Booking group accommodations at Tremblant means experiencing a world of comfort and luxury, of natural settings and amenities. It\'s time to treat yourself to a larger-than-life experience in a breathtaking atmosphere. Tremblant is more than a resort, it\'s: ',
    'exp_text_1': 'Accessible and convenient: A charming pedestrian village easily accessible from cities like Montreal, Ottawa or Toronto.',
    'exp_text_2': 'Naturally wonderful: Over 60,000 square feet of meeting space in a breathtaking and out-of-this-world setting.',
    'exp_text_3': 'Dine at any of the resort\'s restaurant options, featuring choices suited for groups of all sizes and dietary preferences.',
    'exp_text_4': 'Choose from a village-full of accommodations to ensure you and your guests feel right at home.',
    'exp_text_5': 'Take part in our many group activities and excursions',
    'exp_text_6': 'A dedicated team to support you and ensure all your needs are met.',

    'heb_title': 'Accomodations',
    'heb_desc': 'Choose the ideal spot for your group, whether you want to stay by the mountain, in the heart of the pedestrian village, or in the outskirts at one of our majestic golf courses.',
    'heb_argument_title_1': 'A wealth of hotels',
    'heb_argument_desc_1': 'Stay at one of our resort\'s 13 hotels  5 of which are world-renowned hotel chains and 1,900 sleeping accommodations available, including hotel rooms, studios, condos and mountaintop residences, find your ideal place to stay.',
    'heb_argument_title_2': 'Amenities',
    'heb_argument_desc_2': 'Rest, recharge, or break a sweat: from private pools, spas, saunas, massage services and gyms, our amenities will make your time here all the more enjoyable.',
    'heb_argument_title_3': 'Rooms to inspire productivity',
    'heb_argument_desc_3': 'Check out our warm and inviting workspaces, designed exclusively for hosting inspiring business events and group sessions.',

    'espace_title': 'Meeting rooms',
    'espace_desc': 'Discover the advantages of working in one of our meeting rooms, relaxing as they are inspiring — essential for your productivity. The workplace, reimagined.',
    'espace_argument_title_1': 'Types of rooms',
    'espace_argument_desc_1': 'Host a memorable event in one of our 50 indoor meeting, conference or exhibitions rooms.',
    'espace_argument_title_2': 'High-speed Internet',
    'espace_argument_desc_2': 'Stay connected and host productive meetings thanks to our high-speed Wi-Fi Internet. Because creativity should have no limits.',
    'espace_argument_title_3': 'Catering',
    'espace_argument_desc_3': 'Take advantage of our wide variety of menus. We offer à la carte options and buffets to help your team fuel up on energy.',

    'cdb_title': 'Camp de Base Tremblant is a place where dreams come to life and aspirations grow. It\'s where your ascent begins.',
    'cdb_sub_title': 'Camp de Base Tremblant has gotten under a makeover. Starting now, enjoy meetings inspired by nature!',
    'cdb_desc': ``,

    'groups_title': 'Group business meetings',
    'groups_desc': 'Tremblant has everything you need to host a successful gathering. We offer guests the opportunity to work in a stimulating environment and participate in invigorating expeditions and activities. Our team of experts is here to guide you and make your time here truly unforgettable.',
    'groups_1': 'Business',
    'groups_2': 'Leisure',
    'groups_3': 'Team<br>building',

    'activite_title': 'Team activites<br>and gastronomy',
    'activite_desc': 'Enrich your meetings with our wide range of activities and fine dining experiences, offered year-round. Explore our breathtaking, natural playground with your whole group.',
    'activite_argument_title_1': 'Activities for adventurers',
    'activite_argument_desc_1': 'Get a move on and explore all that nature has to offer by participating in one of <a href="https://groups.tremblant.ca/planning-tools/page/activities-available-in-tremblant" target="_blank">over 40 activities</a>. For fun times or discovering somewhere new, you\'re sure to find what you\'re looking for embarking on one of our adventures.',
    'activite_argument_title_2': 'Bars and restaurants',
    'activite_argument_desc_2': 'Tremblant is a gourmet destination, ideal for sharing a delicious meal. We\'re home to <a href="https://groups.tremblant.ca/planning-tools/page/dining" target="_blank">over 40 restaurants and bars</a> that will satisfy your cravings.',
    'activite_argument_title_3': 'Team-building activities',
    'activite_argument_desc_3': 'Challenge yourself and your group and build relationships. Embark on any of our natural excursions to enjoy team-building activities like no other.',

    'transport': 'Transport',
    'vols_title': 'Air transportation',
    'vols_sub_title': 'Fly your group into Tremblant',
    'vols_desc': 'Get to Tremblant by air. Porter Airlines offers several direct flights from Toronto\'s Billy Bishop Airport and connecting flights from Ottawa, Sudbury, Windsor, Thunder Bay, Sault Ste. Marie, New York, Boston, Chicago and many more destinations.',
    'vols_cta': 'See the flight schedule',
    'vols_cta_link': '/pdf/horaire-hiver-2022-2023-porter-en.pdf',
    'vols_promo': 'Receive 2 free weekday lift tickets when you fly into Mont-Tremblant International Airport on a Sunday or Monday and fly out on a Thursday or Friday. In addition, get $25 in promotional gaming credit at the Casino de Mont-Tremblant when you book a flight from Toronto’s Billy Bishop Airport to Mont-Tremblant International Airport.',
    'vols_promo_video_link': 'https://www.youtube.com/watch?v=ZxAZlUqsGDg&feature=youtu.be',

    'bus_title': 'Land transportation',
    'bus_sub_title': 'Luxury bus transportation from Montreal',
    'bus_desc': 'Take the road to Tremblant. There are several ground transportation services available from Montreal-Trudeau International Airport, including luxurious coach buses for travelling in total comfort.',
    'bus_cta': 'Plan your itinerary',
    'bus_cta_link': 'https://groups.tremblant.ca/planning-tools/page/ground-accessibility',

    'eco_title': 'Our commitment to sustainability',
    'eco_sub_title': 'Tremblant: Leaders in sustainability',
    'eco_desc': 'In 2021, Mont-Tremblant became the first resort across the province of Quebec to obtain level 3 ECORESPONSIBLE level-3 certification from Ecocert Canada. This certification means that it is possible to offset your carbon footprint while staying at Tremblant. Tremblant is a leader in sustainable development, and with your help, we can continue to build on this mission.',
    'eco_cta': 'Contribute today',
    'eco_cta_link': 'https://www.tremblant.ca/about-us/eco-responsibility?sc_lang=en',

    'contact_title': 'Contact information',
    'copy': '© Tremblant - All rights reserved',
    'politique': 'Privacy policy',
    'politique_link': 'https://www.alterramtnco.com/privacy-policy',
    'condition': 'Terms of use',
    'condition_link': 'https://www.alterramtnco.com/terms-of-use',

    'testimonial_1': `
                As I began planning our event, I was calling each hotel and venue asking for rates and details.  I was thrilled to receive a call from TRA Business Development Manager, Marie-Chantal Thibault, offering to assist with resort options available to me in one regrouped destination proposal. It was magic!  It just made my life easier!
              <strong></strong>
              <span>Ferrari Club of America</span>
    `,
    'testimonial_2': `
                Tremblant offers a complete change of scenery and a stimulating environment that allowed the team to build relationships. We enjoyed the many outdoor activities and Scandinavian baths. Our incentive trip has been a great success. We will come back for sure!
              <strong>Guillaume Brunet</strong>
              <span>President of Substance</span>
    `,
    'testimonial_3': `
                The Gala at Fairmont Tremblant was simply perfect! Having to deal with 20 different food allergies, the Chef still managed to deliver an impressive four service meal. We spent an amazing week in your venue – platinum service from beginning to end. Congratulations to everyone, you have a truly satisfied costumer!
              <strong>Lucie Champagne</strong>
              <span>President of Champagne Événements</span>
    `,
    'testimonial_4': `
                Big THANKS to everyone involved in putting together our corporate event of December 12, 2015 at the Tremblant Convention Centre. Our custom turnkey experience was a complete success from start to finish! The team provided us with personnalised service and listened to our every need, all in an atmosphere that was relaxed and professional at the same time! A true five star experience!
              <strong>Stéfanie Bouchard</strong>
              <span>Brioconcept</span>
    `,
    'testimonial_5': `
                Tremblant is a hidden gem among the many popular incentive destinations in Canada and should be on every planner's short list for future meetings and events.
              <strong>Thriven Financial</strong>
    `,
    'testimonial_6': `
                It was a pleasure to work with you and the Tremblant team on the planning and implementation of the Egg Farmers of Canada 2016 Summer Conference. Your team’s friendly and knowledgeable assistance helped our organization plan and implement a very successful annual conference for approximately 300 delegates. We appreciated your input, enthusiasm and attention to detail; but most of all we appreciated all your hard work and quick turnaround. Thanks again for helping make our 2016 EFC Summer Conference a very memorable event!
              <strong>Paula Panetta</strong>
              <span>Egg Farmers of Canada</span>
    `,
    'testimonial_7': `
                Between the warm welcome that we got, efficiency of facilities and quality of catering services, all elements came together to make this conference a resounding success.
              <strong>Neil Levecque</strong>
              <span>SCHL</span>
    `,
    'testimonial_8': `
                The hard work & experience of the Tremblant Resort Association Business Development team helped in making our event the success that it was, and it was a true pleasure to be hosted by the Fairmont. The hotel staff were professional and very kind.  I commend everyone for an excellent stay, dining experience & service. 
              <strong>Jackie Brown</strong>
              <span>Office Manager, Administrator<br>Teamsters Canada - Western Regional Office</span>
    `,

    'form_name': 'First Name',
    'form_last_name': 'Last Name',
    'form_mail': 'Email',
    'form_phone': 'Phone',
    'form_city': 'City',
    'form_pays': 'Country',
    'form_type': 'Organisation type',
    'form_type_0': 'Please select',
    'form_type_1': 'Social club',
    'form_type_2': 'Corporation',
    'form_type_3': 'Association',
    'form_type_4': 'Government',
    'form_type_5': 'School group',
    'form_type_6': 'Family / Friends',
    'form_type_7': 'Sports Club / Training camp',
    'form_type_8': 'Wedding',
    'form_type_9': 'Prom / Graduation ball',
    'form_type_10': 'Third party',
    'form_type_11': 'Other',
    'form_business': 'Company (If applicable)',
    'form_participants': 'Number of participants (18 y/o and over)',
    'form_participants_under_18': 'Number of participants (under 18)',
    'form_arrival': 'Arrival date',
    'form_departure': 'Departure date',
    'form_chambre': 'Number of room nights per night',
    'form_lodging': 'Lodging type',
    'form_lodging_0': 'Please select',
    'form_lodging_1': 'Traditional hotel rooms',
    'form_lodging_2': 'Studios or 1-bedroom condos',
    'form_lodging_3': '2- or 3-bedroom condos',
    'form_lodging_4': 'Flexible',
    'form_salle': 'Do you need meeting or event rooms?',
    'form_oui': 'yes',
    'form_non': 'no',
    'form_activites': 'What kind of activities would interest?',
    'form_activites_0': 'Please select',
    'form_activites_1': 'Ski / Golf',
    'form_activites_2': 'Teambuilding',
    'form_activites_3': 'Relaxation / Spa',
    'form_activites_4': 'Festival / Events',
    'form_activites_5': 'Sports (Biking, Snowshoeing, Crosscountry Skiing, etc.)',
    'form_activites_6': 'Extreme Adventure (Arbre en arbre, Ziptrek, Karting, etc.)',
    'form_activites_7': 'Office party package',
    'form_details': 'Details of needs (food, assembly, schedule)',
    'form_upload': 'Upload your detailed Request for proposal (RFP) here',
    'form_upload_file': '(extension allowed: jpeg/jpg/doc/docx/pdf/xls/xlsx/csv)',
    'form_more_details': 'Additional details',
    'form_send': 'Send',
    'ou': 'Or',

    'promo_hero_title': 'Office Party<br>Package',
    'promo_hero_deal': 'Starting from<br />$215 per person',
  },
}

export default trans
